import React from 'react';
import PreTitle from '../PreTitle';

function HomeTechnologies({ title, text, itemsA, itemsB }) {
  return (
    <section className="HomeTechnologies">
      <div className="HomeTechnologies__background" />

      <div className="HomeTechnologies__container">
        <div className="HomeTechnologies__cont">
          <PreTitle
            className="HomeTechnologies__preTitle"
            title={'Technology'}
          />

          <h2 className="HomeTechnologies__title">
            {title}
          </h2>

          <div className="HomeTechnologies__text">
            {text}
          </div>
        </div>

        <div>
          <div className="HomeTechnologies__logos">
            {itemsA.map(technology => {
              return (
                <Logo {...technology} />
              );
            })}
          </div>
          <div className="HomeTechnologies__logos">
            {itemsB.map(technology => {
              return (
                <Logo {...technology} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

function Logo(technology) {
  return (
    <img
      src={technology.logo && technology.logo.publicURL}
      className="HomeTechnologies__logo"
      alt={`${technology.name} logo`}
      key={technology.name}
      style={{
        width: technology.width,
        height: technology.height
      }}
    />
  );
}

export default HomeTechnologies;