import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';

import BackgroundTopSrc from '../../img/svg/pageHome/solutions_top_bg.svg';
import BackgroundBottomSrc from '../../img/svg/pageHome/solutions_bottom_bg.svg';
import Bars1Src from '../../img/svg/pageHome/bar-1.svg';
import Bars2Src from '../../img/svg/pageHome/bar-2.svg';

import CardStatic from '../CardStatic';
import PreTitle from '../PreTitle';

function HomeSolutions({ heading, lead, textA, solutions }) {
  return (
    <section className="HomeSolutions" id="sectionSolutions">
      <SVG
        src={BackgroundTopSrc}
        uniquifyIDs={false}
        className="HomeSolutions__background HomeSolutions__background--top"
      />

      <div className="HomeSolutions__container">
        <div className="HomeSolutions__content">
          <SVG
            src={Bars2Src}
            className="HomeSolutions__bar HomeSolutions__bar--2"
          />

          <PreTitle 
            title={'Our solutions'}
            variants={['white']}
          />

          <h2 className="HomeSolutions__title">{heading}</h2>

          {/* <p className="HomeSolutions__lead">{lead}</p> */}

          <p className="HomeSolutions__text">{textA}</p>

          <SVG
            src={Bars1Src}
            className="HomeSolutions__bar HomeSolutions__bar--1"
          />
        </div>

        <ul className="HomeSolutions__items">
          <li className="HomeSolutions__item HomeSolutions__item--empty"></li>
          {solutions &&
            solutions.map((item, i) => (
              <CardStatic
                key={i}
                tagName="li"
                className="HomeSolutions__item"
                iconSrc={item.icon && item.icon.publicURL}
                title={item.name}
                text={item.text}
              >
                {item.text}
                <Link className="HomeSolutions__item__link" to={item.slug}></Link>
              </CardStatic>
            ))}
        </ul>
      </div>

      <SVG
        src={BackgroundBottomSrc}
        uniquifyIDs={false}
        className="HomeSolutions__background HomeSolutions__background--bottom"
      />
    </section>
  );
}

export default HomeSolutions;
