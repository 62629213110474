import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import HomeHero from '../components/HomeSections/HomeHero';
import HomeClients from '../components/HomeSections/HomeClients';
import HomeAbout from '../components/HomeSections/HomeAbout';
import HomeServices from '../components/HomeSections/HomeServices';
import HomeSolutions from '../components/HomeSections/HomeSolutions';
import HomeTechnologies from '../components/HomeSections/HomeTechnologies';
import HomeConference from '../components/HomeSections/HomeConference';
import Experience from '../components/sections/Experience';

export const IndexPageTemplate = ({
  hero,
  client,
  services,
  technologies,
  about,
  solution,
  conference,
  experience
}) => (
  <>
    <HomeHero {...hero} />

    <HomeSolutions {...solution} />

    <HomeAbout {...about} />

    <HomeServices {...services} />

    <HomeTechnologies {...technologies} />

    <HomeClients {...client} clients={client.logos} />

    <HomeConference {...conference} />
    
    <Experience 
      heading={experience.heading}
      items={experience.items}
    />
  </>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const dataWorkshops = data.allMarkdownRemark.edges;

  const dataWorkshopsFormatted = dataWorkshops.map(function(elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;

    return {
      ...elem.node.frontmatter,
      slug: slug // should be last 
    };
  });

  const mergedWorkshops = {...frontmatter.workshops, ...{items: dataWorkshopsFormatted}};
  const mergedData = {...frontmatter, ...{workshops: mergedWorkshops}};

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <IndexPageTemplate {...mergedData} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        subtitle
      }
      client {
        heading
        text
        subtext
        logos {
          label
          value
        }
        opinionsClients {
          label
          value
        }
      }
      services {
        heading
        lead
        text
        items {
          icon {
            publicURL
            name
          }
          name
          text
        }
      }
      experience {
        heading
        items {
          text
          title
          icon {
            publicURL
            name
          }
          slug
        }
      }
      about {
        heading
        lead
        text
      }
      solution {
        heading
        textA
        solutions {
          icon {
            publicURL
            name
          }
          name
          text
          slug
        }
      }
      technologies {
        title
        text
        itemsA {
          logo {
            publicURL
            name
          }
          name
          width
          height
        }
        itemsB {
          logo {
            publicURL
            name
          }
          name
          width
          height
        }
      }
      workshops {
        heading
        text
      }
      company {
        heading
        text
        counters {
          amount
          isPrecise
          description
        }
      }
      conference {
        logo {
          publicURL
          name
        }
        title
        subtitle
        lead
        text
        url
        textBtn
        counters {
          amount
          isPrecise
          description
        }
      }
    }
  }
  allMarkdownRemark(
    limit: 6
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "workshop-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredWorkshop
          slug
          image {
            publicURL
            name
          }
          is_new_date
        }
      }
    }
  }
}`;
