import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import Button from '../Button';
import BackgroundSrc from '../../img/svg/pageHome/hero_bg.svg';
import IllustrationBarsSrc from '../../img/svg/pageHome/hero_bars.svg';
import IllustrationPNG from '../../img/png/hero_illustration.png';
import IllustrationWEBP from '../../img/webp/hero_illustration.webp';
import ImageStarsSrc from '../../img/svg/stars.svg';

import scrollTo from '../../helpers/scrollTo';

function HomeHero({ title, subtitle }) {
  const [isMainSVGLoaded, setIsMainSVGLoaded] = useState(false);

  const classNameBackground = classNames('HomeHero__background', {
    'HomeHero__background--noImage': !isMainSVGLoaded
  });

  return (
    <section className="HomeHero">
      <div className="Layout__background">
        <SVG src={ImageStarsSrc} className="Layout__stars" />
      </div>

      <div className={classNameBackground}>
        <SVG
          src={BackgroundSrc}
          uniquifyIDs={false}
          className="HomeHero__background-image"
          onError={error => console.log(error.message)}
          onLoad={(src, hasCache) => setIsMainSVGLoaded(true)}
        />
      </div>

      <div className="HomeHero__container">
        <div className="HomeHero__illustration">
          <picture>
            <source srcSet={IllustrationWEBP} type="image/webp" />
            <source srcSet={IllustrationPNG} type="image/png" /> 
            <img className="HomeHero__image" src={IllustrationPNG} alt="big data illustration" />
          </picture>
          <SVG
            src={IllustrationBarsSrc}
            className="HomeHero__image HomeHero__image--bars"
          />
        </div>

        <div className="HomeHero__content">
          {/* <h2 className="HomeHero__subtitle">{subtitle}</h2> */}
          <h1 className="HomeHero__title">{title}</h1>

          <Button
            className="HomeHero__action"
            onClick={() => scrollTo('sectionContact')}
            variants={['wide']}
            id="main_hero_btn_1"
          >
            Contact us
          </Button>

          <Button
            className="HomeHero__action"
            onClick={() => scrollTo('sectionSolutions')}
            variants={['empty']}
            id="main_hero_btn_2"
          >
            See our solutions
          </Button>
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
