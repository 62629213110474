import React from 'react';
import SVG from 'react-inlinesvg';

import Counter from '../Counter';
import Button from '../Button';
import PreTitle from '../PreTitle';

import Bars1Src from '../../img/svg/pageHome/bar-5.svg';
import Bars2Src from '../../img/svg/pageHome/bar-6.svg';
import Bars3Src from '../../img/svg/pageHome/bar-7.svg';

function HomeConference({ logo, title, url, textBtn, subtitle, lead, text, counters }) {
  return (
    <section className="HomeConference">
      <div className="HomeConference__background">
        <div className="HomeConference__container">
          <div className="HomeConference__content">
            <PreTitle
              title={'Community'}
              variants={['white']}
              className="HomeConference__preTitle"
            />

            <img
              src={logo && logo.publicURL}
              className="HomeConference__logo"
              alt="Big Data Tech logo"
            />

            <h2 className="HomeConference__title">{title}</h2>

            <h3 className="HomeConference__subtitle">{subtitle}</h3>

            <p className="HomeConference__text">
              {lead}
              <br />
              <br />
              {text}
            </p>
            {url && 
              <Button
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="HomeConference__action"
                variants={['wide']}
              >
                {textBtn.length ? textBtn : 'See More'}
              </Button>
            }
          </div>
          

          <div className="HomeConference__items">
            {counters.map((item, i) => (
              <div className="HomeConference__item-wrapper" key={i}>
                <Counter
                  key={i}
                  tagName="div"
                  className="HomeConference__item"
                  {...item}
                />
                {i === 0 &&
                  <SVG
                    src={Bars3Src}
                    className="HomeConference__bar HomeConference__bar--1"
                  />
                }
                {i === 1 &&
                  <SVG
                    src={Bars2Src}
                    className="HomeConference__bar HomeConference__bar--2"
                  />
                }
                {i === 2 &&
                  <SVG
                    src={Bars1Src}
                    className="HomeConference__bar HomeConference__bar--3"
                  />
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeConference;
