import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  tagName: PropTypes.string,
  amount: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  isPrecise: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.oneOf(['on-background', 'small'])),
  className: PropTypes.string,
};

const defaultProps = {
  tagName: 'span',
  isPrecise: false,
  variants: [],
  className: '',
};

function Counter({
  tagName,
  amount,
  description,
  isPrecise,
  variants,
  className,
}) {
  const variantClasses = variants.map(variant => `Counter--${variant}`);
  const classes = classNames(className, 'Counter', variantClasses);
  const TagName = tagName;

  return (
    <TagName className={classes}>
      <span className="Counter__amount">
        {amount}
        {isPrecise ? '' : '+'}
      </span>

      <span className="Counter__description">{description}</span>
    </TagName>
  );
}

Counter.propTypes = propTypes;
Counter.defaultProps = defaultProps;

export default Counter;
