import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { StaticImage } from 'gatsby-plugin-image';

import PreTitle from '../PreTitle';

function HomeAbout({ heading, lead, text }) {
  return (
    <section className="HomeAbout">
      <div className="HomeAbout__container">
        <div className="HomeAbout__content">
          <PreTitle 
            title={'Why us?'}
          />

          <h2 className="HomeAbout__title">{heading}</h2>

          <ReactMarkdown 
            className="HomeAbout__lead" 
            children={lead} 
            rehypePlugins={[rehypeRaw]}
          />
          <br/>
          <p className="HomeAbout__text">
            {text}
          </p>
        </div>
        <StaticImage
          src={'../../img/png/pageHome/about_us.png'}
          placeholder="blurred"
          alt="Why you should work with GetInData"
        />
      </div>
    </section>
  );
}

export default HomeAbout;
