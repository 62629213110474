import React from 'react';

import Button from '../Button';
import PreTitle from '../PreTitle';
import CardStatic from '../CardStatic';

function HomeServices({ heading, lead, text, items }) {
  return (
    <section className="HomeServices">
      <div className="HomeServices__background">    
        <div className="HomeServices__container">
          <ul className="HomeServices__items">
            {items &&
              items.map((item, i) => (
                <CardStatic
                  key={i}
                  tagName="li"
                  className="HomeServices__item"
                  iconSrc={item.icon && item.icon.publicURL}
                  title={item.name}
                  text={item.text}
                />
              ))}
          </ul>

          <div className="HomeServices__content">
            <PreTitle
              className="HomeServices__preTitle"
              title={'More services'}
            />
        
            <h2 className="HomeServices__title">{heading}</h2>

            <p className="HomeServices__lead">{lead}</p>

            <p className="HomeServices__text">{text}</p>
            
            <br/><br/>
            <Button
              className="HomeServices__btn"
              to="/services"
              id="main_services_btn_1"
            >
              Our Services
            </Button>
            <Button
              className="HomeServices__btn"
              to="/big-data-training"
              variants={['empty']}
              id="main_services_btn_2"
            >
              Training
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeServices;
