import React from 'react';
import classNames from 'classnames';
import { compact } from 'lodash';
import SVG from 'react-inlinesvg';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';

import PreTitle from '../PreTitle';

import DataClients from '../../data/clients.json';
import DataTestimonials from '../../data/testimonials.json';
import IconStarSrc from '../../img/svg/icons/star-yellow.svg';
import IconStarHalfSrc from '../../img/svg/icons/star-half-yellow.svg';
import Bars1Src from '../../img/svg/pageHome/bar-3.svg';
import Bars2Src from '../../img/svg/pageHome/bar-4.svg';

SwiperCore.use([Autoplay]);

const LinkClient = ({ url, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const LogoClient = ({ logoMono, logoColor, name, maxWidth = null, colored = false }) => (
  <>
    {colored ? (
      <img
        src={logoColor}
        className="HomeClients__logo"
        style={{
          ...maxWidth ? { maxWidth: maxWidth + 'px' } : {}
        }}
        alt={`${name} logo color`}
      />
    ) : (
      <>
        <img
          src={logoMono}
          className="HomeClients__logo"
          style={{
            ...maxWidth ? { maxWidth: maxWidth + 'px' } : {}
          }}
          alt={`${name} logo`}
        />
        <img
          src={logoColor}
          className="HomeClients__logo HomeClients__logo--color"
          style={{
            ...maxWidth ? { maxWidth: maxWidth + 'px' } : {}
          }}
          alt={`${name} logo color`}
        />
      </>
    )}
  </>
);

const LogosItems = ({ clients, className = '', colored = false }) => {
  const classes = classNames(className, 'HomeClients__items');

  return (
    <div className={classes}>
      {clients && clients.map(client => {
        let props = {
          ...(client.position && client.position.length > 1 &&
            { style: { "transform": "translate(" + client.position + ")" } })
        };
        return (
          <div key={client.name} className="HomeClients__items__item" {...props}>
            {client.url ? (
              <LinkClient url={client.url}>
                <LogoClient
                  logoMono={client.logoMono}
                  logoColor={client.logoColor}
                  name={client.name}
                  colored={colored}
                  maxWidth={client.maxWidth}
                />
              </LinkClient>
            ) : (
              <LogoClient
                logoMono={client.logoMono}
                logoColor={client.logoColor}
                name={client.name}
                colored={colored}
                maxWidth={client.maxWidth}
              />
            )}
          </div>
        )
      })}
    </div>
  );
};

const Opinion = ({ text, author, avatar, authorTitle, reversed = false }) => {
  const opinionClassName = classNames('HomeClients__opinion', {
    'HomeClients__opinion--reverse': reversed,
  });

  return (
    <div className={opinionClassName}>
      <div className="HomeClients__opinion__text">
        {text}
        <div className="HomeClients__opinion__text__title">{author}, {authorTitle}</div>
      </div>
      <img className="HomeClients__opinion__avatar" src={avatar} alt={author} />
    </div>
  )
}

const dataClients = (clientsFiltered) => {
  let items = clientsFiltered;
  let count = items.length;

  return {
    first: items.slice(0, 6),
    second: items.slice(6, count - 6),
    third: items.slice(count - 6)
  }
}

function HomeClients({ heading, text, subtext, opinionsClients, clients }) {
  const clientsFiltered = compact(clients?.map((item, i) => {
    return DataClients?.items?.find(o => item.value == o.id)
  }));

  const opinionsFiltered =
    DataTestimonials.projects && DataTestimonials.projects.length && opinionsClients && opinionsClients.length
      ? DataTestimonials.projects.filter(o => opinionsClients.find(o2 => o.id == o2.value)) : null; // must be =

  const params = {
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    loopedSlides: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  }

  const dataOpinions = () => {
    let items = opinionsFiltered;
    let count = items.length;
    let middle = count / 2;
    if (count % 2 == 1) {
      middle = (count + 1) / 2
    }

    return {
      first: items.slice(0, middle),
      second: items.slice(middle)
    }
  }

  return (
    <section className="HomeClients">
      <div className="HomeClients__background" />

      <div className="HomeClients__container">
        <div className="HomeClients__content">
          <PreTitle
            title={'Clients'}
          />

          <div className="HomeClients__title-wrapper">
            <SVG
              src={Bars2Src}
              className="HomeClients__bar HomeClients__bar--1"
            />

            <h2 className="HomeClients__title">{heading}</h2>

            <SVG
              src={Bars1Src}
              className="HomeClients__bar HomeClients__bar--2"
            />
          </div>

          <ReactMarkdown
            className="HomeClients__text"
            children={text}
            rehypePlugins={[rehypeRaw]}
          />

          <img className="HomeClients__star" src={IconStarSrc} alt="" />
          <img className="HomeClients__star" src={IconStarSrc} alt="" />
          <img className="HomeClients__star" src={IconStarSrc} alt="" />
          <img className="HomeClients__star" src={IconStarSrc} alt="" />
          <img className="HomeClients__star" src={IconStarHalfSrc} alt="" />

          <div className="HomeClients__textStar">{subtext}</div>
        </div>

        <div className="HomeClients__clients">
          <div className="HomeClients__clients__row">
            <div className="HomeClients__clients__row__col">
              <LogosItems clients={dataClients(clientsFiltered).first} />
            </div>
            <div className="HomeClients__clients__row__col">
              <Swiper {...params}>
                {dataOpinions().first.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Opinion
                      {...item}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="HomeClients__clients__row">
            <div className="HomeClients__clients__row__col">
              <LogosItems clients={dataClients(clientsFiltered).second} />
            </div>
          </div>
          <div className="HomeClients__clients__row">
            <div className="HomeClients__clients__row__col">
              <Swiper {...params}>
                {dataOpinions().second.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Opinion
                      {...item}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="HomeClients__clients__row__col">
              <LogosItems clients={dataClients(clientsFiltered).third} />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default HomeClients;
